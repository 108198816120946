import "./App.css";
import { useState, useCallback } from "react";
import myCloseModal from "react";
import P2P from "./P2P"
import Graph from "./Graph"
import Home from "./Home"
import News from "./News"
import Predict from "./Predict"
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { Component } from "react";
import CanvasJSReact from "./canvasjs.react";
import { getUserToken, saveUserToken, clearUserToken } from "./localStorage";
import UserCredentialsDialog from "./UserCredentialsDialog/UserCredentialsDialog";
var SERVER_URL = "https://alpha-exchange-rate.herokuapp.com/";
const States = {
  PENDING: "PENDING",
  USER_CREATION: "USER_CREATION",
  USER_LOG_IN: "USER_LOG_IN",
  USER_AUTHENTICATED: "USER_AUTHENTICATED",
};

function App() {
  let [userToken, setUserToken] = useState(getUserToken());
  let [authState, setAuthState] = useState(States.PENDING);
  let [datar, setDatar] = useState({ averagesLbpToUsd: [], dates: [] });
  let [newscaster,setnewscaster]=useState("");
  

  /*    setlbpratechange(data["lbpRateChangeLastWeek"]);
    setnumoflbptrans(data["numberOfLbpTransactionsThisWeek"]);
    setnumoftransw(data["numberOfTransactionsThisWeek"]);
    setnumoftransm(data["numberOfUsdTransactionsThisWeek"]);
    settotaltrans(data["totalTransactionsToday"]);
    setusdratechange(data["usdRateChangeLastWeek"]);*/
  function login(username, password) {
    return fetch(`${SERVER_URL}/authentication`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((body) => {
        console.log(body);
        setAuthState(States.USER_AUTHENTICATED);
        setUserToken(body.token);
        saveUserToken(body.token);  
        setnewscaster(body.role);
        console.log(userToken);
      });
  }
  function logout() {
    setUserToken(null);
    clearUserToken();
  }
  function createUser(username, password) {
    return fetch(`${SERVER_URL}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: username,
        password: password,
      }),
    }).then((response) => login(username, password));
  }
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    myCloseModal();
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: "#4A7C59" }}>
        <Toolbar classes={{ root: "nav" }}>
          <Typography variant="h5">PAGE HEADER</Typography>
          <a href="/">Home</a>
          <a href="/p2p">P2P</a>
          <a href="/graphs">Graph</a>
          <a href="/news">News</a>
          <a href="/predict">Predict</a>
          <div>
            {userToken !== null ? (
              <Button color="inherit" onClick={logout}>
                Logout
              </Button>
            ) : (
              <div>
                <Button
                  color="inherit"
                  onClick={() => setAuthState(States.USER_CREATION)}
                >
                  Register
                </Button>
                <Button
                  color="inherit"
                  onClick={() => setAuthState(States.USER_LOG_IN)}
                >
                  Login
                </Button>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Snackbar
        elevation={6}
        variant="filled"
        open={authState === States.USER_AUTHENTICATED}
        autoHideDuration={2000}
        onClose={() => setAuthState(States.PENDING)}
      >
        <Alert severity="success">Success</Alert>
      </Snackbar>
      <UserCredentialsDialog
          open={authState == States.USER_CREATION}
          onSubmit={createUser}
          submitText="Register"
          onClose={handleClose}
        />
        <UserCredentialsDialog
          open={authState == States.USER_LOG_IN}
          onSubmit={login}
          submitText="Log In"
        />
      <Router>
      <Routes>
        <Route path="/p2p" element={<P2P userToken={userToken}/>}/>
        <Route path="/" element={<Home />}/>
        <Route path="/graphs" element={<Graph/>}/>
        <Route path="/news" element={<News role={newscaster} userToken={userToken} />}/>
        <Route path="/predict" element={<Predict  />}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
