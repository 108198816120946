import "./App.css";
import { useState, useCallback } from "react";
import myCloseModal from "react";

import { useEffect } from "react";
import {
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import CanvasJSReact from "./canvasjs.react";
import { getUserToken, saveUserToken, clearUserToken } from "./localStorage";
import { DataGrid } from "@mui/x-data-grid";
var SERVER_URL = "https://alpha-exchange-rate.herokuapp.com/";
const States = {
  PENDING: "PENDING",
  USER_CREATION: "USER_CREATION",
  USER_LOG_IN: "USER_LOG_IN",
  USER_AUTHENTICATED: "USER_AUTHENTICATED",
};

function Home() {
  let [buyUsdRate, setBuyUsdRate] = useState(null);
  let [sellUsdRate, setSellUsdRate] = useState(null);
  let [lbpInput, setLbpInput] = useState("");
  let [usdInput, setUsdInput] = useState("");
  let [transactionType, setTransactionType] = useState("usd-to-lbp");
  let [dataType, setDataType] = useState("Daily");
  let [userToken, setUserToken] = useState(getUserToken());
  let [financialcal, setFinancialcalc] = useState("");
  let [caltrans, setfincaltrans] = useState("usd-to-lbp");
  let [caldisplay, setcaldisplay] = useState(null);
  let [userTransactions, setUserTransactions] = useState([]);
  let [datar, setDatar] = useState({ averagesLbpToUsd: [], dates: [] });
  let [lbpRateChangeLastWeek, setlbpratechange] = useState(null);
  let [numberOfLbpTransactionsThisWeek, setnumoflbptrans] = useState(null);
  let [numberOfTransactionsThisWeek, setnumoftransw] = useState(null);
  let [numberOfUsdTransactionsThisWeek, setnumoftransm] = useState(null);
  let [authState, setAuthState] = useState(States.PENDING);
  let [totalTransactionsToday, settotaltrans] = useState(null);
  let [usdRateChangeLastWeek, setusdratechange] = useState(null);
  let [newscaster,setnewscaster]=useState("");
  let [startdate,setstartdate]=useState("");
  let [enddate,setenddate]=useState("");
  

  /*    setlbpratechange(data["lbpRateChangeLastWeek"]);
    setnumoflbptrans(data["numberOfLbpTransactionsThisWeek"]);
    setnumoftransw(data["numberOfTransactionsThisWeek"]);
    setnumoftransm(data["numberOfUsdTransactionsThisWeek"]);
    settotaltrans(data["totalTransactionsToday"]);
    setusdratechange(data["usdRateChangeLastWeek"]);*/
  console.log(dataType);

  function logout() {
    setUserToken(null);
    clearUserToken();
  }
  function meanstdev() {
    /* fetch statement to get the data values for the average meant*/
    /*
    sum=0;
    for (int i: data){
      sum+=i;
    }
    average=sum/data.size();
    sum=0;
    for (int i:data){
      sum+= ((i-average)*(i-average))
    }
    stdev=Math.sqrt(sum/data.szie())

    */
  }
  function login(username, password) {
    return fetch(`${SERVER_URL}/authentication`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((body) => {
        console.log(body);
        setAuthState(States.USER_AUTHENTICATED);
        setUserToken(body.token);
        saveUserToken(body.token);  
        setnewscaster(body.role);
        console.log(userToken);
      });
  }
  function calculate() {
    console.log(caltrans);
    fetch(`${SERVER_URL}/exchangeRate`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log(data["usd_to_lbp"]);
        if (caltrans == "usd-to-lbp") {
          setcaldisplay((financialcal * data["usd_to_lbp"]).toFixed(2));
          console.log(sellUsdRate);
        } else {
          setcaldisplay((financialcal * data["lbp_to_usd"]).toFixed(2));
        }
      });
  }
  function createUser(username, password) {
    return fetch(`${SERVER_URL}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name: username,
        password: password,
      }),
    }).then((response) => login(username, password));
  }
  const fetchUserTransactions = useCallback(() => {
    fetch(`${SERVER_URL}/transaction`, {
      headers: {
        Authorization: `bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((transactions) => setUserTransactions(transactions));
  }, [userToken]);
  function fetchRates() {
    fetch(`${SERVER_URL}/exchangeRate`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log(data["usd_to_lbp"]);
        console.log(data["lbp_to_usd"]);
        console.log("chu bek");
        setSellUsdRate(
          "LBP to USD:" +
            data["usd_to_lbp"].toFixed(2) +
            " USD to LBP: " +
            (1 / data["usd_to_lbp"]).toFixed(2)
        );
        setBuyUsdRate(
          "LBP to USD:" +
            data["lbp_to_usd"].toFixed(2) +
            " USD to LBP: " +
            (1 / data["lbp_to_usd"]).toFixed(2)
        );
      });
  }
  function fetchstats() {
    fetch(`${SERVER_URL}/statistics`, {

       method:"POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
        "timeFormat":dataType,
         "startDate":startdate,
         "endDate":enddate
       }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setlbpratechange(data["lbpRateChangeBasedOnTimeFormatBetweenDates"].toFixed(2));
        setnumoflbptrans(data["numberOfLbpTransactionsBetweenDates"]);
        setnumoftransw(data["numberOfTransactionsBetweenDates"]);
        setnumoftransm(data["numberOfUsdTransactionsBetweenDates"]);
        settotaltrans(data["totalTransactionsToday"]);
        setusdratechange(data["usdRateChangeBasedOnTimeFormatBetweenDates"]);
      });
  }
  useEffect(fetchRates, []);
  function addItem() {
    let usd = usdInput;
    let lbp = lbpInput;
    let usd_to = transactionType == "usd-to-lbp";
    console.log(usd_to);
    console.log(lbp);
    console.log(document.getElementById("transaction-type").value);
    let trans = {
      usd_amount: usd,
      lbp_amount: lbp,
      usd_to_lbp: usd_to,
    };
    fetch(`${SERVER_URL}/transaction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(trans),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        fetchRates();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const column = [
    {
      field: "usd_amount",
      headerName: "USD",
      width: 170,
    },
    {
      field: "lbp_amount",
      headerName: "LBP",
      width: 170,
    },
    {
      field: "usd_to_lbp",
      headerName: "USDTOLBP",
      width: 170,
    },
    {
      field: "added_date",
      headerName: "Date Added",
      width: 170,
    },
  ];
  return (
    <div>
      <body style={{ backgroundColor: "#8FC0A9" }}>
        <div className="wrapper" style={{ backgroundColor: "#FAF3DD" }}>
          <div className="wrapper" style={{ backgroundColor: "#FAF3DD" }}>
            <hr />
            <h2>Financial Calculator</h2>
            <form name="transaction-entry">
              <div className="amount-input">
                <label htmlFor="lbp-amount">Amount in USD</label>
                <input
                  id="USD-amount1"
                  type="number"
                  value={financialcal}
                  onChange={(e) => setFinancialcalc(e.target.value)}
                />
              </div>
              <select
                id="transaction-type"
                onChange={(e) => setfincaltrans(e.target.value)}
              >
                <option value="usd-to-lbp">USD to LBP</option>
                <option value="lbp-to-usd">LBP to USD</option>
              </select>
              <button
                id="cal-button"
                className="button"
                type="button"
                onClick={calculate}
              >
                Calculate
              </button>
              <h3>
                Amount in LBP: <span id="caloutput">{caldisplay} LL.</span>
              </h3>
            </form>
          </div>
          <hr />

          <h2>Today's Exchange</h2>
          <p>LBP to USD Exchange Rate</p>
          <h3>
            Buy USD: <span id="buy-usd-rate">{buyUsdRate}</span>
          </h3>
          <h3>
            Sell USD: <span id="sell-usd-rate">{sellUsdRate} </span>
          </h3>
          <hr />
          <h2>Record a recent transaction</h2>
          <form name="transaction-entry">
            <div className="amount-input">
              <label htmlFor="lbp-amount">LBP Amount</label>
              <input
                id="lbp-amount"
                type="number"
                value={lbpInput}
                onChange={(e) => setLbpInput(e.target.value)}
              />
            </div>
            <div className="amount-input">
              <label htmlFor="usd-amount">USD Amount</label>
              <input
                id="usd-amount"
                type="number"
                value={usdInput}
                onChange={(e) => setUsdInput(e.target.value)}
              />
            </div>
            <button
              id="add-button"
              className="button"
              type="button"
              onClick={addItem}
            >
              Add
            </button>
          </form>
          <select
            id="transaction-type"
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <option value="usd-to-lbp">USD to LBP</option>
            <option value="lbp-to-usd">LBP to USD</option>
          </select>
          {userToken && (
            <div className="wrapper" style={{ backgroundColor: "#C8D5B9" }}>
              <Typography variant="h5">Your Transactions</Typography>
              <DataGrid columns={column} rows={userTransactions} autoHeight />
            </div>
          )}
          <div>
            <h2>Statistics</h2>
          </div>
          <select
              className="select-box"
              id="data-type"
              onChange={(e) => setDataType(e.target.value)}
              style={{ padding: "10px" }}
            >
              <option value="">--Select a statistics format--</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Hourly">Hourly</option>
            </select>
          <p>Start Date</p>
          <input id="startdate" type="date" onChange={(e) => setstartdate( new Date(e.target.value).getTime() / 1000)} />
            <p>End Date</p>
            <input id="enddate" type="date"  onChange={(e) => setenddate(new Date(e.target.value).getTime() / 1000)}/>
            <button
                id="stat-button"
                className="button"
                type="button"
                onClick={fetchstats}
              >
                 Statistics
                 </button>
          <div>
            {" "}
            <h3>Change of the LBP rate</h3>
            <span id="lbprate">{lbpRateChangeLastWeek} </span>
            <br></br>
            <h3>Number of LBP Transactions during that Timespan</h3>
            <span id="numberoflbptransactionsthisweek" value={numberOfLbpTransactionsThisWeek}>
              {numberOfLbpTransactionsThisWeek}{" "}
            </span>
            <br></br>
            <h3>Number of USD Transactions during that Timespan</h3>
            <span id="numberofusdtransactionsthisweek">
              {numberOfUsdTransactionsThisWeek}
            </span>
            <br></br>
            <h3>Number of Total Transactions during that Timespan</h3>
            <span id="numberoftransactionsthisweek">
              {numberOfTransactionsThisWeek}
            </span>
            <br></br>
            <h3> Total Transactions Today</h3>
            <span id="totaltransactions">{totalTransactionsToday}</span>
            <br></br>
            <h3>Change of the USD rate in that Timespan</h3>
            <span id="usdratechanges">{usdRateChangeLastWeek}</span>{" "}
          </div>
        </div>

        <script src="script.js"></script>
      </body>
    </div>
  );
}

export default Home;
