import "./App.css";

import { useState, useCallback } from "react";
import myCloseModal from "react";
import { useEffect } from "react";
import React, { Component } from "react";
var SERVER_URL = "https://alpha-exchange-rate.herokuapp.com/";
const States = {
  PENDING: "PENDING",
  USER_CREATION: "USER_CREATION",
  USER_LOG_IN: "USER_LOG_IN",
  USER_AUTHENTICATED: "USER_AUTHENTICATED",
};
 function P2P(props){
    let [offers,setoffers]=useState([]);
    let [buySellToggle, setBuySellToggle] = useState("buy");
    let [lbpInput, setLbpInput] = useState("");
    let [usdInput, setUsdInput] = useState("");
    let [transactionType, setTransactionType] = useState("usd-to-lbp");
    let [phonenumber, setphone] = useState("");
    let [useroffers,setuseroffers]=useState([]);
    const handleToggleClick = (event) => {
        setBuySellToggle(event.target.value);
      };
        const fetchuseroffers= useCallback(() => {
            fetch(`${SERVER_URL}/usertransaction/list/offers`)
              .then((response) => response.json())
              .then((data) => setoffers(data));
          }, [props.userToken]);
          useEffect(() => {
            if (props.userToken) {
              fetchuseroffers();
            }
          }, [fetchuseroffers, props.userToken]);
      function reservetransaction(id){
        fetch(`${SERVER_URL}/usertransaction/reserve`,{
            method:"PUT",
            headers:{
                "Content-Type": "application/json",
                Authorization:`Bearer ${props.userToken}`
            },
            body: JSON.stringify({
                "usertransaction_id":id
            }),
        })
      }
      const getmytransactions= useCallback(() => {
        fetch(`${SERVER_URL}/usertransaction/list/user`,{
            headers:{
                Authorization:`Bearer ${props.userToken}`
            }
        })
          .then((response) => response.json())
          .then((data) => setuseroffers(data));
      }, [props.userToken]);
      useEffect(() => {
        if (props.userToken) {
          getmytransactions();
        }
      }, [getmytransactions, props.userToken]);
      function confirmtransaction(id){
        fetch(`${SERVER_URL}/usertransaction/confirm`,{
            method:"PUT",
            headers:{
                "Content-Type": "application/json",
                Authorization:`Bearer ${props.userToken}`
            },
            body: JSON.stringify({
                "usertransaction_id":id
            }),
        })

      }
      function posttransaction(){

        let usd_to=false;
        console.log(usdInput)
        console.log(lbpInput)
        console.log(phonenumber)
        console.log(transactionType)
        console.log(props.userToken)
        if (transactionType=="usd-to-lbp") usd_to=true;
        fetch(`${SERVER_URL}/usertransaction`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:`Bearer ${props.userToken}`
            },
            body: JSON.stringify({
                "usd_amount": usdInput,
                "lbp_amount":lbpInput,
                "usd_to_lbp":usd_to,
                "seller_phone_number": phonenumber
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Success:", data);
            })
            fetchuseroffers();
            
      }
      return <div>
         <h1>Available Offers</h1>
        <table>
          <thead>
            <tr>
              <th key="trade">Trader</th>
              <th key="status">Status</th>
              <th key="price">Price</th>
              <th key="amount">Amount</th>
              <th key="buy/sell">Buy/Sell</th>
            </tr>
          </thead>
          <tbody>
        {Object.keys(offers).map((key) => (
            <tr>
            <td>{offers[key].seller_username}</td>
            <td>{offers[key].status}</td>
            <td>{offers[key].usd_amount} $</td>
            <td>{offers[key].lbp_amount} LL.</td>
            <td>
              <button onClick={() => reservetransaction(offers[key].id)}>{offers[key].usd_to_lbp === true ? "Buy" : "Sell"}</button>
            </td>
          </tr>
            ))}
          </tbody>
        </table>
        <div>
            <h1>Post a transaction</h1>
        <label htmlFor="lbp-amount">LBP Amount</label>
              <input
                id="lbp-amount"
                type="number"
                value={lbpInput}
                onChange={(e) => setLbpInput(e.target.value)}
              />
               <div className="amount-input">
              <label htmlFor="usd-amount">USD Amount</label>
              <input
                id="usd-amount"
                type="number"
                value={usdInput}
                onChange={(e) => setUsdInput(e.target.value)}
              />
            </div>
            <select
            id="transaction-type"
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <option value="usd-to-lbp">USD to LBP</option>
            <option value="lbp-to-usd">LBP to USD</option>
          </select>
          <label htmlFor="phonenumber">Phone Number</label>
              <input
                id="phone"
                type="number"
                value={phonenumber}
                onChange={(e) => setphone(e.target.value)}
              />
            </div>
            <button
              id="sell-button"
              className="button"
              type="button"
              onClick={posttransaction}
            >
              Add
            </button>
            <h1>My Offers</h1>
        <table>
          <thead>
            <tr>
              <th key="status">Status</th>
              <th key="price">Price</th>
              <th key="amount">Amount</th>
              <th key="party">Party</th>
              <th key="confirm">Confirm</th>
            </tr>
          </thead>
          <tbody>
        {Object.keys(useroffers).map((key) => (
            <tr>
            <td>{useroffers[key].status}</td>
            <td>{useroffers[key].usd_amount} $</td>
            <td>{useroffers[key].lbp_amount} LL.</td>
            <td>{useroffers[key].seller_username}</td>
            <td>
            {useroffers[key].seller_username ? <button onClick={() => confirmtransaction(useroffers[key].id)}>Confirm</button> : <button>Deny</button>}
            </td>
          </tr>
            ))}
          </tbody>
        </table>

        </div>
}
export default P2P;