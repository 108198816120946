import "./App.css";
import { useState, useCallback } from "react";
import React, { Component } from "react";
import CanvasJSReact from "./canvasjs.react";
import { getUserToken, saveUserToken, clearUserToken } from "./localStorage";
import UserCredentialsDialog from "./UserCredentialsDialog/UserCredentialsDialog";
import { Bar, Line } from "react-chartjs-2";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement,
} from "chart.js";
import { COMPACT_DENSITY_FACTOR } from "@mui/x-data-grid/hooks/features/density/useGridDensity";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement
);
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var SERVER_URL = "https://alpha-exchange-rate.herokuapp.com/";
const States = {
  PENDING: "PENDING",
  USER_CREATION: "USER_CREATION",
  USER_LOG_IN: "USER_LOG_IN",
  USER_AUTHENTICATED: "USER_AUTHENTICATED",
};
function Graph(){
    let [datar, setDatar] = useState({ averagesLbpToUsd: [], dates: [] });
    let [startdate,setstartdate]=useState("");
    let [enddate,setenddate]=useState("");
    const fetchdata = useCallback(async (option) => {
        if (option!=""){
        fetch(`${SERVER_URL}/transaction/datapoints`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "timeFormat": option,
            "startDate":startdate,
            "endDate":enddate
          }),
          
        })
          .then((response) => response.json())
          .then((response_data) => setDatar(response_data));
          console.log(enddate);
      }
      else setDatar("");
      });
      const dataLine = {
        labels: datar["dates"],
        datasets: [
          {
            data: datar["averagesLbpToUsd"],
          },
        ],
      };
      const dataBar = {
        labels: datar["dates"],
        datasets: [
          {
            data: datar["averagesLbpToUsd"],
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          },
        ],
      };
      console.log(datar);
      const options = {
        labels: datar["dates"],
        datasets: [
          {
            data: datar["averagesLbpToUsd"],
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          },
        ],
      };
      return <div>
        <div>
            <select
              class="select-box"
              id="data-type"
              onChange={(e) => fetchdata(e.target.value)}
              style={{ padding: "10px" }}
            >
              <option value="">--Select a delay--</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Hourly">Hourly</option>
            </select>
            <p>Start Date</p>
            <input id="startdate" type="date" onChange={(e) => setstartdate( new Date(e.target.value).getTime() / 1000)} />
            <p>End Date</p>
            <input id="enddate" type="date"  onChange={(e) => setenddate(new Date(e.target.value).getTime() / 1000)}/>

          </div>
          <div>
            <Line data={dataLine} options={options}></Line>
          </div>
          <div>
            <Bar data={dataBar} options={options}></Bar>
          </div>
      </div>
}
export default Graph;