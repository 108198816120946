import "./App.css";
import { useState, useCallback } from "react";

import { useEffect } from "react";
import React, { Component } from "react";
var SERVER_URL = "https://alpha-exchange-rate.herokuapp.com/";
const States = {
  PENDING: "PENDING",
  USER_CREATION: "USER_CREATION",
  USER_LOG_IN: "USER_LOG_IN",
  USER_AUTHENTICATED: "USER_AUTHENTICATED",
};

function News(props) {
    let [news, setnewsdata] = useState([]);
    let [newscaster,setnewscaster]=useState("");
    let [newsoffer,setnewsoffer]=useState("");
    function postnews(){
        fetch(`${SERVER_URL}/news/post`,{
       method:"POST",
       headers: {
         "Content-Type": "application/json",
         'Authorization': `Bearer ${props.userToken}`
       },
       body: JSON.stringify({
         "news":newsoffer
       }),
     }) 
     fetchNews();
     }
    const fetchNews=useCallback(()=>{
    fetch(`${SERVER_URL}/news`)
      .then((response) => response.json())
      .then((data)=>setnewsdata(data));
  },[]);
  useEffect(fetchNews, []);
  return  <div class="container">
  <h1>Latest News</h1>
    {Object.keys(news).map((key) => (
      <div class="news-block">
        <div class="newspaper-image">
          <img
            src="newspaper.png"
            alt="Newspaper"
            style={{ width: "100px", height: "80px" }}
          />
        </div>
        <div class="news-content">
          <h2 class="news-title"></h2>
          <p class="news-description">{news[key].news}</p>
          <div class="news-meta">
            <div class="news-category">{news[key].newscaster_username}</div>
            <div class="news-date">{news[key].added_date}</div>
          </div>
        </div>
      </div>
    ))}

{props.role=="newscaster" && (
    <div className="wrapper" style={{ backgroundColor: "#C8D5B9" }}>
      <textarea
        id="news"
        onChange={(e) => setnewsoffer(e.target.value)}
      />
        <button
        id="cal-buttonbj"
        className="button"
        type="button"
        onClick={postnews}
      >
        Post
        </button>
    </div>
  )}
  </div>

}
export default News;